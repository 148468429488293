// action types
export const SET_CUSTOMER_ORDER_INFO = 'setOrderCustomerInfo';
export const PURGE_CUSTOMER_ORDER_INFO = 'removeOrderCustomerInfo';

const orderCustomerInfoBase = {
  customerId: null,
  customerMobile: null,
  customerName: null,
  customerType: null,
  customerCity: null,
  customerDistrict: null,
  customerWard: null,
  customerAddress: null,
  totalPoint: 0,
  customerEmail: null,
};
export default {
  state: {
    orderCustomerInfo: {
      ...orderCustomerInfoBase
    }
  },
  getters: {
    /**
     * Get cusomter infor
     * @param state
     * @returns {*}
     */
    getOrderCustomerInfo(state) {
      return state.orderCustomerInfo;
    }
  },
  actions: {
    [SET_CUSTOMER_ORDER_INFO](state, payload) {
      state.commit(SET_CUSTOMER_ORDER_INFO, payload);
    },
    [PURGE_CUSTOMER_ORDER_INFO](state) {
      state.commit(PURGE_CUSTOMER_ORDER_INFO);
    }
  },
  mutations: {
    [SET_CUSTOMER_ORDER_INFO](state, payload) {
      state.orderCustomerInfo = payload;
    },
    [PURGE_CUSTOMER_ORDER_INFO](state) {
      state.orderCustomerInfo = { ...orderCustomerInfoBase };
    }
  }
};
